import { Container, Heading6, Paragraph } from "shared/components";

import styles from "./Content005.module.scss";
import { MediaItem } from "./MediaItem";

export const Content005 = ({ payload }) => {
  const title = payload?.title ?? "";
  const facts = payload?.facts ?? [];
  const gallery = payload?.gallery ?? [];

  const filteredGallery = gallery.filter((item) => Boolean(item.image));
  const formatCounter = (number) => (number > 9 ? number : `0${number}`);

  return (
    <div className={styles.wrapper}>
      <Container className={styles.inner}>
        <div className={styles.content}>
          <Paragraph as="h2" className={styles.title} appearance="bold">
            {title}
          </Paragraph>

          <ol className={styles.list}>
            {facts.map((item, index) => (
              <li className={styles["list-item"]} key={item.id}>
                <Heading6 color="brand" as="span" plain={true}>
                  {formatCounter(index + 1)}
                </Heading6>
                <Heading6 as="p">{item.title}</Heading6>
              </li>
            ))}
          </ol>
        </div>

        <div className={styles.media}>
          {filteredGallery.map((item) => (
            <MediaItem {...item} key={item.id} />
          ))}
        </div>
      </Container>
    </div>
  );
};

export default Content005;
