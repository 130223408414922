import {
  Action,
  Icon,
  ImageLite,
  ModalWrapper,
  YoutubeVideo,
} from "shared/components";
import { useToggle } from "shared/hooks";
import { parseYouTubeId } from "shared/utils";

import styles from "./MediaItem.module.scss";

export const MediaItem = ({ videoLink, image }) => {
  const videoId = parseYouTubeId(videoLink);

  const { opened, onOpen, onClose } = useToggle();

  return (
    <div className={styles.wrapper}>
      <ImageLite image={image} className={styles.image} />
      <div className={styles.content}>
        {videoId && (
          <Action onClick={onOpen} className={styles.play}>
            <Icon name="play" theme="white" />
          </Action>
        )}
        {opened && (
          <ModalWrapper onCloseModal={onClose} appearance="full-area">
            <YoutubeVideo id={videoId} />
          </ModalWrapper>
        )}
      </div>
    </div>
  );
};
